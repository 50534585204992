import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "roadPointAdd",
  mixins: [mixins],
  data() {
    return {
      src: '',
      edit: false,
      dialogVisible: false,
      dialogImageUrl: '',
      units: [{
        name: '元'
      }, {
        name: '角'
      }, {
        name: '分'
      }],
      precision: 2,
      formModel: {
        id: null,
        name: null,
        dates: [],
        beginTime: null,
        endTime: null,
        couponTimes: 0,
        //券数量
        couponExpirationType: '固定时间',
        //券失效方式
        couponExpirationTime: null,
        //券固定失效时间
        couponExpriationDynamicUnit: null,
        //动态时间单位
        couponExpriationDynamicValue: null,
        //动态时间数量
        money: null,
        // 优惠金额
        photo: null,
        // 宣传图 base64
        imgs: [],
        // 宣传图 图片

        unit: '分',
        employ: '',
        promotionType: '固定金额',
        totalAmount: null,
        minAmount: null,
        maxAmount: null,
        accessAddress: null
      },
      emploies: [{
        name: '全部',
        value: ''
      }, {
        name: '微信小程序',
        value: '微信小程序'
      }, {
        name: '安卓APP',
        value: '安卓APP'
      }, {
        name: '苹果APP',
        value: '苹果APP'
      }],
      formRules: {
        name: [{
          required: true,
          message: '请输入优惠活动名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '活动名称长度不能超过32',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入优惠活动编码',
          trigger: 'blur'
        }, {
          max: 32,
          message: '活动编码长度不能超过32',
          trigger: 'blur'
        }],
        couponTimes: [{
          required: true,
          message: '请输入活动券数量',
          trigger: 'blur'
        }],
        money: [{
          required: true,
          message: '请输入优惠券金额',
          trigger: 'blur'
        }],
        unit: [{
          required: true,
          message: '请选择单位',
          trigger: 'blur'
        }],
        promotionType: [{
          required: true,
          message: '请选择优惠类型',
          trigger: 'blur'
        }],
        employ: [{
          required: true,
          message: '请选择使用端',
          trigger: 'blur'
        }],
        totalAmount: [{
          required: true,
          message: '请输入总金额',
          trigger: 'blur'
        }],
        minAmount: [{
          required: true,
          message: '请输入随机最小金额',
          trigger: 'blur'
        }],
        maxAmount: [{
          required: true,
          message: '请输入随机最大金额',
          trigger: 'blur'
        }],
        couponExpriationDynamicValue: [{
          required: true,
          message: '请输入动态时间数量',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    changeUnit() {
      if (this.formModel.unit === '元') {
        this.precision = 0;
      } else if (this.formModel.unit === '角') {
        this.precision = 1;
      } else if (this.formModel.unit === '分') {
        this.precision = 2;
      }
    },
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true;
    },
    changeFile(file, fileList) {
      this.formModel.imgs = fileList;
      if (file.size / 1024 / 1024 > 2) {
        ElMessage.error('图片大小不能超过2M。');
        const currIdx = this.formModel.imgs.indexOf(file);
        this.formModel.imgs.splice(currIdx, 1);
        return;
      } else {
        this.getBase64(file.raw);
      }
    },
    getBase64(file) {
      //把图片转成base64编码
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formModel.photo = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (this.formModel.couponExpirationType === '动态时间') {
            if (!(this.formModel.couponExpriationDynamicUnit && this.formModel.couponExpriationDynamicValue)) {
              ElMessage.error('请选择单位');
              return;
            }
          }
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
            } else {
              ElMessage.error(res.message);
            }
          };
          this.formModel.beginTime = this.formModel.dates[0];
          this.formModel.endTime = this.formModel.dates[1];
          this.$api.business.ridePromotion.save(this.formModel).then(callBack);
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      const loading = ElLoading.service();
      this.$api.business.ridePromotion.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
          this.formModel.dates = [this.formModel.beginTime, this.formModel.endTime];
        } else if (res.code === 121701) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};